var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container section address-section"},[_c('title-with-tooltip',{attrs:{"label":_vm.$t('tooltip_text')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('title')))]},proxy:true}])}),_c('div',{staticClass:"columns is-mobile is-multiline pt-5 pb-1"},[_c('div',{staticClass:"column is-10-tablet is-12-mobile"},[_c('auto-complete',{attrs:{"placeholder":_vm.placeholder,"read-only":!_vm.isDraft,"search-mode":_vm.searchMode},on:{"selectedItem":function($event){return _vm.setAddress($event)}}})],1),(_vm.show_structured_address)?_c('div',{staticClass:"column is-10-tablet is-12-mobile pt-3 "},[_c('h3',{staticClass:"has-text-weight-medium has-text-black pt-5 mt-1"},[_vm._v(" "+_vm._s(_vm.$t('control_address'))+" ")])]):_vm._e(),(_vm.show_structured_address)?_c('div',{staticClass:"column is-10-tablet is-12-mobile ",attrs:{"id":"extra"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_c('b-field',{staticClass:"mt-1",attrs:{"label":_vm.$t('street'),"expanded":"","type":{ 'is-danger': _vm.$v.addressInfo.streetname.$error },"message":[
                            !_vm.$v.addressInfo.streetname.required &&
                            _vm.$v.addressInfo.streetname.$error
                                ? _vm.$t('field_required')
                                : null ]}},[_c('b-input',{attrs:{"size":"is-medium","placeholder":"Kleine Schuurstraat","disabled":!_vm.isDraft},on:{"blur":function($event){return _vm.$v.addressInfo.streetname.$touch()},"input":function($event){return _vm.$v.addressInfo.streetname.$touch()}},model:{value:(_vm.streetname),callback:function ($$v) {_vm.streetname=$$v},expression:"streetname"}})],1)],1)]),_c('div',{staticClass:"columns is-variable is-multiline is-6"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.$t('number'),"expanded":"","type":{
                            'is-danger': _vm.$v.addressInfo.streetnumber.$error,
                        },"message":[
                            !_vm.$v.addressInfo.streetnumber.required &&
                            _vm.$v.addressInfo.streetnumber.$error
                                ? _vm.$t('field_required')
                                : null ]}},[_c('b-input',{attrs:{"size":"is-medium","placeholder":"8","disabled":!_vm.isDraft},on:{"blur":function($event){return _vm.$v.addressInfo.streetnumber.$touch()},"input":function($event){return _vm.$v.addressInfo.streetnumber.$touch()}},model:{value:(_vm.streetnumber),callback:function ($$v) {_vm.streetnumber=$$v},expression:"streetnumber"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.$t('box'),"expanded":""}},[_c('b-input',{attrs:{"size":"is-medium","placeholder":"123","disabled":!_vm.isDraft},model:{value:(_vm.boxnumber),callback:function ($$v) {_vm.boxnumber=$$v},expression:"boxnumber"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.$t('postalcode'),"expanded":"","type":{
                            'is-danger': _vm.$v.addressInfo.postalcode.$error,
                        },"message":[
                            _vm.$v.addressInfo.postalcode.$error &&
                            !_vm.$v.addressInfo.postalcode.required
                                ? _vm.$t('field_required')
                                : null,
                            _vm.$v.addressInfo.postalcode.$error &&
                            !_vm.$v.addressInfo.postalcode.minLength
                                ? _vm.$t('min_4_characters')
                                : null,
                            _vm.$v.addressInfo.postalcode.$error &&
                            !_vm.$v.addressInfo.postalcode.numeric
                                ? _vm.$t('only_numbers')
                                : null ]}},[_c('b-input',{attrs:{"size":"is-medium","placeholder":"0960","disabled":!_vm.isDraft},on:{"blur":function($event){return _vm.$v.addressInfo.postalcode.$touch()},"input":function($event){return _vm.$v.addressInfo.postalcode.$touch()}},model:{value:(_vm.postalcode),callback:function ($$v) {_vm.postalcode=$$v},expression:"postalcode"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.$t('municipality'),"expanded":"","type":{
                            'is-danger': _vm.$v.addressInfo.municipality.$error,
                        },"message":[
                            !_vm.$v.addressInfo.municipality.required &&
                            _vm.$v.addressInfo.municipality.$error
                                ? _vm.$t('field_required')
                                : null ]}},[_c('b-input',{attrs:{"size":"is-medium","placeholder":"Gent","disabled":!_vm.isDraft},on:{"blur":function($event){return _vm.$v.addressInfo.municipality.$touch()},"input":function($event){return _vm.$v.addressInfo.municipality.$touch()}},model:{value:(_vm.municipality),callback:function ($$v) {_vm.municipality=$$v},expression:"municipality"}})],1)],1)])]):_vm._e(),_c('div',{staticClass:"column is-10-tablet is-12-mobile mt-5"},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"arrow-left"},on:{"click":_vm.prevPage}},[_c('span',[_vm._v(_vm._s(_vm.$t('previous')))])]),(_vm.show_next)?_c('b-button',{attrs:{"disabled":_vm.goNextDisabled || _vm.$store.state.loading,"size":"is-medium","type":"is-primary","icon-right":"arrow-right","loading":_vm.$store.state.loading},on:{"click":function($event){return _vm.nextPage(false)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('next')))])]):_vm._e()],1)])]),_c('b-modal',{attrs:{"trap-focus":"","has-modal-card":""},on:{"close":_vm.closeDestructiveSaveModal},model:{value:(_vm.isDestructiveSave),callback:function ($$v) {_vm.isDestructiveSave=$$v},expression:"isDestructiveSave"}},[_c('div',{staticClass:"card modal-card p-5 destructive-save-modal"},[_c('h4',{staticClass:"is-size-5 has-text-weight-bold has-text-black"},[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.title'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.content'))+" ")]),_c('div',{staticClass:"btn-group"},[_c('b-button',{on:{"click":_vm.closeDestructiveSaveModal}},[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.cancel'))+" ")]),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.nextPage(true)}}},[_vm._v(" "+_vm._s(_vm.$t('destructive_safeguard.confirm'))+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }