var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bubble py-2 px-3 mb-2",class:{ self: _vm.isOwn }},[_c('div',{staticClass:"message-meta"},[_c('h4',{staticClass:"chat-sender",domProps:{"innerHTML":_vm._s(
                _vm.isOwn
                    ? 'You'
                    : _vm.isAgent
                    ? ("Bank agent (<b>" + _vm.surname + " " + _vm.name + "</b>)")
                    : 'Rock.estate Valuation Specialist' +
                      (_vm.isValuer ? (" (<b>" + _vm.surname + " " + _vm.name + "</b>)") : '')
            )}}),_c('h4',{staticClass:"chat-time"},[_c('b-tooltip',{attrs:{"label":_vm.detailedTime,"position":_vm.isOwn ? 'is-left' : 'is-right',"type":"is-black"}},[_vm._v(" "+_vm._s(_vm.timespan)+" ")])],1)]),_c('p',[_vm._v(_vm._s(_vm.content))])])}
var staticRenderFns = []

export { render, staticRenderFns }