var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-flex choose-type-group",class:[
        _vm.$vssWidth <= 1123 && 'is-flex-direction-column is-justify-content-center' ]},[_c('transition',{attrs:{"name":"fade-opacity","duration":{ leave: 0 }}},[(_vm.allowedBuildingTypes.includes('house'))?_c('div',{ref:"box",class:{
                'choose-type-box': true,
                'not-choosen': _vm.value !== 'house',
                chosen: _vm.value === 'house',
                disable: !_vm.isDraft,
            },on:{"click":function($event){return _vm.selectType('house')}}},[_c('div',{staticClass:"type-icon home"},[_c('svg-icon',{attrs:{"icon":"home","hight":"50","scale":"1.4","color":"#798DA6"}})],1),_c('div',{staticClass:"type-text"},[_c('div',{staticClass:"type-text-title has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('house'))+" ")]),_c('div',{staticClass:"type-text-text"},[_vm._v(" "+_vm._s(_vm.$t('house_comment'))+" ")])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade-opacity","duration":{ leave: 0 }}},[(_vm.allowedBuildingTypes.includes('apartment'))?_c('div',{staticClass:"choose-type-box",class:{
                chosen: _vm.value === 'apartment',
                'not-choosen': _vm.value !== 'apartment',
                disable: !_vm.isDraft,
            },on:{"click":function($event){return _vm.selectType('apartment')}}},[_c('div',{staticClass:"type-icon apartment"},[_c('svg-icon',{attrs:{"icon":"apartment","hight":"50","scale":"1.4","color":"#798DA6"}})],1),_c('div',{staticClass:"type-text"},[_c('div',{staticClass:"type-text-title has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('apartment'))+" ")]),_c('div',{staticClass:"type-text-text"},[_vm._v(" "+_vm._s(_vm.$t('apartment_comment'))+" ")])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade-opacity","duration":{ leave: 0 }}},[(_vm.allowedBuildingTypes.includes('new_house'))?_c('div',{staticClass:"choose-type-box",class:{
                chosen: _vm.value === 'new_house',
                'not-choosen': _vm.value !== 'new_house',
                disable: !_vm.isDraft,
            },on:{"click":function($event){return _vm.selectType('new_house')}}},[_c('div',{staticClass:"type-icon with-stroke"},[_c('svg-icon',{attrs:{"icon":"new-construction","hight":"50","scale":"1.4","color":"#798DA6","fill":false}})],1),_c('div',{staticClass:"type-text"},[_c('div',{staticClass:"type-text-title has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('new_house'))+" ")]),_c('div',{staticClass:"type-text-text"},[_vm._v(" "+_vm._s(_vm.$t('new_house_comment'))+" ")])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade-opacity","duration":{ leave: 0 }}},[(_vm.allowedBuildingTypes.includes('new_apartment'))?_c('div',{staticClass:"choose-type-box",class:{
                chosen: _vm.value === 'new_apartment',
                'not-choosen': _vm.value !== 'new_apartment',
                disable: !_vm.isDraft,
            },on:{"click":function($event){return _vm.selectType('new_apartment')}}},[_c('div',{staticClass:"type-icon with-stroke"},[_c('svg-icon',{attrs:{"icon":"new-construction","hight":"50","scale":"1.4","color":"#798DA6","fill":false}})],1),_c('div',{staticClass:"type-text"},[_c('div',{staticClass:"type-text-title has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('new_apartment'))+" ")]),_c('div',{staticClass:"type-text-text"},[_vm._v(" "+_vm._s(_vm.$t('new_apartment_comment'))+" ")])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade-opacity","duration":{ leave: 0 }}},[(_vm.allowedBuildingTypes.includes('construction_plot'))?_c('div',{staticClass:"choose-type-box",class:{
                chosen: _vm.value === 'construction_plot',
                'not-choosen': _vm.value !== 'construction_plot',
                disable: !_vm.isDraft,
            },on:{"click":function($event){return _vm.selectType('construction_plot')}}},[_c('div',{staticClass:"type-icon"},[_c('svg-icon',{attrs:{"icon":"apartment","hight":"50","scale":"1.4","color":"#798DA6"}})],1),_c('div',{staticClass:"type-text"},[_c('div',{staticClass:"type-text-title has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('construction_plot'))+" ")]),_c('div',{staticClass:"type-text-text"},[_vm._v(" "+_vm._s(_vm.$t('construction_plot_comment'))+" ")])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }