<i18n>
{
  "nl-BE": {
    "building_type": "Type woning",
    "address_confirmation": "Adres",
    "extra_info": "Extra informatie",
    "house": "Huis",
    "apartment": "Appartement",
    "new_house": "Nieuwbouw woning",
    "new_apartment": "Nieuwbouw appartement",
    "construction_plot": "Bouwgrond",
    "feedback": "Feedback",
    "back": "Vorige",
    "customer_ref": "Interne referentie",
    "chat": {
        "button": "Chat",
        "title": "Your conversation with the Valuation Specialist"
    },
    "ing": {
        "customer_ref": "LOP ID"
    }
  },
  "en-BE": {
    "building_type": "Building type",
    "address_confirmation": "Address confirmation",
    "extra_info": "Extra information",
    "house": "House",
    "apartment": "Apartment",
    "new_house": "New house",
    "new_apartment": "New apartment",
    "construction_plot": "Construction plot",
    "feedback": "Feedback",
    "back": "Back",
    "customer_ref": "Customer reference",
    "chat": {
        "button": "Chat",
        "title": "Your conversation with the Valuation Specialist"
    },
    "ing": {
        "customer_ref": "LOP ID"
    }
  },
  "fr-BE": {
    "building_type": "Type de bien",
    "address_confirmation": "Confirmation de l'adresse",
    "extra_info": "Informations complémentaires",
    "house": "Maison",
    "apartment": "Appartement",
    "new_house": "Maison neuve",
    "new_apartment": "Appartement neuf",
    "construction_plot": "Terrain à bâtir",
    "feedback": "Feedback",
    "back": "Précédent",
    "customer_ref": "Référence interne",
    "chat": {
        "button": "Chat",
        "title": "Your conversation with the Valuation Specialist"
    },
    "ing": {
        "customer_ref": "LOP ID"
    }
  }
}
</i18n>

<template>
    <div class="side-stepper" :class="{ 'pt-7': $route.params.action }">
        <div v-if="$route.params.action == 'faq'">
            <div class="is-flex is-align-items-center is-justify-content-center px-5">
                <b-button
                    icon-left="arrow-left"
                    type="is-primary"
                    class="is-capitalized is-back"
                    size="is-medium"
                    expanded
                    @click="$router.go(-1)"
                >
                    {{ $t('back') }}
                </b-button>
            </div>
        </div>
        <div
            v-else
            class="is-flex is-flex-direction-column is-align-items-flex-start pl-6"
        >
            <!-- step -->
            <div
                v-if="flow_type !== 'avm_demo'"
                class="is-flex is-align-items-center is-icon-box"
            >
                <div
                    class="is-icon-box-wrapper is-flex is-align-items-center is-justify-content-center"
                    :class="{
                        'has-background-primary-light': routeChange === 1,
                        'has-background-success-light': routeChange > 1,
                    }"
                >
                    <svg-icon
                        v-if="routeChange == 0"
                        icon="location"
                        width="25"
                        height="25"
                        color="#798DA6"
                    />
                    <svg-icon
                        v-else-if="routeChange == 1"
                        icon="location"
                        width="25"
                        height="25"
                        color="#0096FF"
                    />
                    <svg-icon
                        v-else
                        icon="check"
                        width="25"
                        height="25"
                        color="#52D860"
                    />
                </div>
                <div class="is-flex is-flex-direction-column">
                    <span
                        class="pl-4"
                        :class="{
                            'has-text-weight-bold has-text-black': routeChange === 1,
                        }"
                    >
                        {{ t('customer_ref') }}
                    </span>
                    <span class="pl-4 has-text-black has-text-weight-semibold">
                        {{ this.$store.state.customer_ref || '' }}
                    </span>
                </div>
            </div>
            <!-- step -->
            <div class="is-flex is-align-items-center is-icon-box">
                <div
                    class="is-icon-box-wrapper is-flex is-align-items-center is-justify-content-center"
                    :class="{
                        'has-background-primary-light': routeChange === 2,
                        'has-background-success-light': routeChange > 2,
                    }"
                >
                    <svg-icon
                        v-if="routeChange < 2"
                        icon="house"
                        width="25"
                        height="25"
                        color="#798DA6"
                    />
                    <svg-icon
                        v-else-if="routeChange === 2"
                        icon="house"
                        width="25"
                        height="25"
                        color="#0096FF"
                    />
                    <svg-icon
                        v-else
                        icon="check"
                        width="25"
                        height="25"
                        color="#52D860"
                    />
                </div>
                <div class="is-flex is-flex-direction-column">
                    <span
                        class="pl-4"
                        :class="{
                            'has-text-weight-bold has-text-black': routeChange === 2,
                        }"
                    >
                        {{ $t('building_type') }}
                    </span>
                    <span class="pl-4 has-text-black has-text-weight-semibold">
                        {{
                            this.$store.state.Address.features.f_building_type
                                ? $t(this.$store.state.Address.features.f_building_type)
                                : ' '
                        }}
                    </span>
                </div>
            </div>
            <!-- step -->
            <div class="is-flex is-align-items-center is-icon-box">
                <div
                    class="is-icon-box-wrapper is-flex is-align-items-center is-justify-content-center"
                    :class="{
                        'has-background-primary-light': routeChange === 3,
                        'has-background-success-light': routeChange > 3,
                    }"
                >
                    <svg-icon
                        v-if="routeChange < 3"
                        icon="location"
                        width="25"
                        height="25"
                        color="#798DA6"
                    />
                    <svg-icon
                        v-else-if="routeChange == 3"
                        icon="location"
                        width="25"
                        height="25"
                        color="#0096FF"
                    />
                    <svg-icon
                        v-else
                        icon="check"
                        width="25"
                        height="25"
                        color="#52D860"
                    />
                </div>
                <div class="is-flex is-flex-direction-column">
                    <span
                        class="pl-4"
                        :class="{
                            'has-text-weight-bold has-text-black': routeChange === 3,
                        }"
                    >
                        {{ $t('address_confirmation') }}
                    </span>
                    <span class="pl-4 has-text-weight-semibold has-text-black">
                        {{ getAddress.short_address }}
                    </span>
                </div>
            </div>

            <!-- step -->
            <div class="is-flex is-align-items-center is-icon-box">
                <div
                    class="is-icon-box-wrapper is-flex is-align-items-center is-justify-content-center"
                    :class="{
                        'has-background-primary-light': routeChange === 4,
                        'has-background-success-light': routeChange > 4,
                    }"
                >
                    <svg-icon
                        v-if="routeChange < 4"
                        icon="ruler"
                        width="25"
                        height="25"
                        color="#798DA6"
                    />
                    <svg-icon
                        v-else-if="routeChange === 4"
                        icon="ruler"
                        width="25"
                        height="25"
                        color="#0096FF"
                    />
                    <svg-icon
                        v-else
                        icon="check"
                        width="25"
                        height="25"
                        color="#52D860"
                    />
                </div>
                <span
                    class="pl-4"
                    :class="{
                        'has-text-weight-bold has-text-black': routeChange === 4,
                    }"
                >
                    {{ $t('extra_info') }}
                </span>
            </div>
            <!-- step -->
            <div class="is-flex is-align-items-center is-icon-box">
                <div
                    class="is-icon-box-wrapper is-flex is-align-items-center is-justify-content-center"
                    :class="{
                        'has-background-primary-light': routeChange === 5,
                        'has-background-success-light': routeChange > 5,
                    }"
                >
                    <b-icon
                        :type="{
                            'is-primary': routeChange === 5,
                            'is-success': routeChange > 5,
                        }"
                        :icon="fifthhStep"
                    ></b-icon>
                </div>
                <span
                    class="pl-4"
                    :class="{
                        'has-text-weight-bold has-text-black': routeChange === 5,
                    }"
                >
                    {{ $t('feedback') }}
                </span>
            </div>

            <b-button
                v-if="isChatAllowed"
                class="mt-6"
                expanded
                type="is-info"
                @click="openChat"
            >
                {{ $t('chat.button') }}
                <b-tag v-if="hasUnreadMessages" rounded type="is-warning">
                    NEW
                </b-tag>
            </b-button>
        </div>

        <b-modal v-if="isChatOpen" v-model="isChatOpen" width="640px">
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{ $t('chat.title') }}</p>
                    <button type="button" class="delete" @click="closeChat" />
                </header>
                <section class="modal-card-body">
                    <chat />
                </section>
                <footer class="modal-card-foot">
                    <div />
                </footer>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { localeMixin } from '@/components/mixins/locale.js'
import Chat from '@/components/common/Chat.vue'

// logic could be change if route structure
export default {
    components: {
        Chat,
    },
    mixins: [localeMixin],
    data() {
        return {
            prevRoute: '/',
            isChatOpen: false,
        }
    },
    computed: {
        ...mapGetters(['getAddress', 'flow_type', 'hasUnreadMessages']),
        isChatAllowed() {
            return (
                typeof process.env.VUE_APP_ENABLE_CHAT !== 'undefined' &&
                process.env.VUE_APP_ENABLE_CHAT.toLowerCase() === 'true'
            )
        },
        routeChange() {
            let counter
            switch (this.$route.params.action) {
                case 'start':
                    counter = 0
                    break
                case 'enter':
                    counter = 1
                    break
                case 'building-type':
                    counter = 2
                    break
                case 'address':
                case 'confirm-main-building':
                case 'select-main-building':
                case 'select-secondary-buildings':
                case 'select-parcels':
                case 'view-building-parcels':
                    counter = 3
                    break
                case 'extra-info':
                    counter = 4
                    break
                case 'feedback':
                    counter = 5
                    break

                default:
                    break
            }
            return counter
        },
        firstStep() {
            let icon
            if (this.routeChange > 1) {
                icon = 'check'
            } else {
                icon = 'map-marker'
            }
            return icon
        },
        secondStep() {
            let icon
            if (this.routeChange > 2) {
                icon = 'check'
            } else {
                icon = 'home'
            }
            return icon
        },
        thirdStep() {
            let icon
            if (this.routeChange > 3) {
                icon = 'check'
            } else {
                icon = 'map-marker'
            }
            return icon
        },
        fourthStep() {
            let icon
            if (this.routeChange > 4) {
                icon = 'check'
            } else {
                icon = 'ruler'
            }
            return icon
        },
        fifthhStep() {
            let icon
            if (this.routeChange > 5) {
                icon = 'check'
            } else {
                icon = 'message-outline'
            }
            return icon
        },
    },
    methods: {
        openChat() {
            this.isChatOpen = true
        },
        closeChat() {
            this.isChatOpen = false
        },
    },
    /* [Mathieu] had to disable that logic for now because it doesn't take into acount query parameters, which may get lost when going to the faq
    watch: {
        $route(to, from) {
            if (to.params.action === 'faq' && from.params.action !== 'faq') {
                this.prevRoute = from.params.action || '/'
            }
        },
    },*/
}
</script>

<style lang="scss" scoped>
.side-stepper {
    height: 100%;
    min-height: calc(100vh - 54px);
    background: #ffffff;
    padding-top: 100px;
    padding-bottom: 0;
    .is-icon {
        &-box:first-child {
            margin-top: 0;
        }
        &-box {
            margin-top: 40px;
            &-wrapper {
                border-radius: 100%;
                background: rgba(121, 141, 166, 0.2);
                padding: 12px;
                width: 50px;
                height: 50px;
            }
        }
    }
    .pt-7 {
        padding-top: 6rem;
    }
}
</style>
