var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{class:{ 'is-fixed-top': _vm.fixed },attrs:{"id":"navbar","type":"is-white"}},[_c('template',{slot:"brand"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":_vm.email
                    ? {
                          name: 'request',
                          params: { action: 'start', lang: this.$i18n.locale },
                      }
                    : { name: 'login', params: { lang: this.$i18n.locale } }}},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/icons/rockestate-logo-horizontal-dark.svg"),"width":"148"}})])],1),(_vm.showMenu)?_c('template',{slot:"start"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":{
                name: 'dashboard',
                params: {
                    lang: _vm.$route.params.lang,
                },
            }}},[_vm._v(" "+_vm._s(_vm.$t('dashboard'))+" ")]),_c('b-navbar-item',{attrs:{"tag":"router-link","to":{
                name: 'support',
                params: {
                    lang: _vm.$route.params.lang,
                },
                query: Object.assign({}, {prev_name: _vm.$route.name,
                    prev_path: _vm.$route.path},
                    _vm.$route.query),
            }}},[_vm._v(" "+_vm._s(_vm.$t('support'))+" ")]),_c('b-navbar-item',{attrs:{"tag":"router-link","to":{
                name: 'request',
                params: {
                    action: 'start',
                    lang: _vm.$route.params.lang,
                },
            }}},[_vm._v(" "+_vm._s(_vm.$t('new_request'))+" ")]),(_vm.roles.includes('admin'))?_c('b-navbar-item',{attrs:{"tag":"router-link","to":{
                name: 'admin',
                params: {
                    lang: _vm.$route.params.lang,
                },
            }}},[_vm._v(" "+_vm._s(_vm.$t('admin'))+" ")]):_vm._e(),(_vm.isValuer && _vm.isDispatchDashboard)?_c('div',{staticClass:"deployment-select mr-2"},[_c('b-select',{attrs:{"expanded":"","disabled":_vm.isLoading}},[_c('option',{attrs:{"disabled":""}},[_vm._v("Deployment")])])],1):_vm._e(),(_vm.isValuer)?_c('div',{ref:"reference_field",staticClass:"ref-search",class:{
                'apply-shake': _vm.shake,
            }},[_c('div',{staticClass:"ref-form"},[_c('b-input',{attrs:{"placeholder":"Go to DVM (enter ref)","disabled":_vm.isLoading},model:{value:(_vm.dvm_ref),callback:function ($$v) {_vm.dvm_ref=$$v},expression:"dvm_ref"}}),_c('b-button',{staticClass:"ml-2",attrs:{"type":"is-primary","disabled":_vm.isDvmRefEmpty || _vm.isLoading},on:{"click":function($event){return _vm.goToDvm(false)}}},[_vm._v(" Go ")]),_c('b-button',{staticClass:"ml-2",attrs:{"type":"is-primary","disabled":_vm.isDvmRefEmpty || _vm.isLoading},on:{"click":function($event){return _vm.goToDvm(true)}}},[_vm._v(" Go (new tab) ")])],1)]):_vm._e()],1):_vm._e(),_c('template',{slot:"end"},[(_vm.showRoleSwitches)?_c('div',{staticClass:"role-switches mr-4 is-flex is-align-items-center is-align-self-center"},[_c('b-switch',{attrs:{"value":_vm.roles.includes('admin'),"size":"is-small"},on:{"input":function($event){return _vm.toggleRole('admin')}}},[_vm._v(" Admin ")]),_c('b-switch',{attrs:{"value":_vm.isValuer,"size":"is-small"},on:{"input":function($event){return _vm.toggleRole('valuer')}}},[_vm._v(" Valuer ")]),_c('b-switch',{attrs:{"value":_vm.isSubstitute,"size":"is-small"},on:{"input":function($event){return _vm.toggleRole('substitute')}}},[_vm._v(" Substitute ")])],1):_vm._e()]),_c('template',{slot:"end"},[_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('div',{staticClass:"langs buttons has-addons my-0"},[_c('b-button',{staticClass:"my-0",attrs:{"size":"is-small","type":{
                        'is-primary': this.$route.params.lang === 'nl-BE',
                    }},nativeOn:{"click":function($event){return _vm.changeLang('nl-BE')}}},[_c('span',[_vm._v("NL")])]),_c('b-button',{staticClass:"my-0",attrs:{"size":"is-small","type":{
                        'is-primary': this.$route.params.lang === 'fr-BE',
                    }},nativeOn:{"click":function($event){return _vm.changeLang('fr-BE')}}},[_c('span',[_vm._v("FR")])]),_c('b-button',{staticClass:"my-0",attrs:{"size":"is-small","type":{
                        'is-primary': this.$route.params.lang === 'en-BE',
                    }},nativeOn:{"click":function($event){return _vm.changeLang('en-BE')}}},[_c('span',[_vm._v("EN")])])],1),_c('div',{staticClass:"mr-4 px-1 is-size-7 user",class:[_vm.email ? 'ml-6' : 'ml-4']},[_vm._v(" "+_vm._s(_vm.email)+" ")]),_c('div',{staticClass:"buttons"},[(_vm.email != null)?_c('a',{attrs:{"href":_vm.logout_url}},[_c('b-button',{attrs:{"size":"is-small","type":"is-primary","outlined":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('logout')))])])],1):_vm._e()])])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }