<i18n>
{
  "nl-BE": {
    "house": "Een huis",
    "house_comment": "Gebouw met één enkele woonentiteit",
    "apartment": "Een appartement",
    "apartment_comment": "Een woonentiteit die deel uitmaakt van een groter gebouw",
    "new_house": "Nieuwbouw woning",
    "new_house_comment": "Nieuwbouw woning",
    "new_apartment": "Nieuwbouw appartement",
    "new_apartment_comment": "Nieuwbouw appartement",
    "construction_plot": "Bouwgrond",
    "construction_plot_comment": "Bouwgrond",
    "previous": "Vorige",
    "next": "Volgende"
  },
  "en-BE": {
    "house": "A house",
    "house_comment": "Building with one single living entity",
    "apartment": "An apartment",
    "apartment_comment": "A living entity part of a larger building",
    "new_house": "New house",
    "new_house_comment": "New house",
    "new_apartment": "New apartment",
    "new_apartment_comment": "New apartment",
    "construction_plot": "A construction plot",
    "construction_plot_comment": "A construction plot",
    "previous": "Previous",
    "next": "Next"
  },
  "fr-BE": {
    "house": "Une maison",
    "house_comment": "Bâtiment comptant une seule entité",
    "apartment": "Un appartement",
    "apartment_comment": "Une entité qui fait partie d'un bâtiment plus large",
    "new_house": "Maison neuve",
    "new_house_comment": "Maison neuve",
    "new_apartment": "Appartement neuf",
    "new_apartment_comment": "Appartement neuf",
    "construction_plot": "Terrain à bâtir",
    "construction_plot_comment": "Terrain à bâtir",
    "previous": "Précédent",
    "next": "Suivant"
  }
}
</i18n>

<template>
    <div>
        <title-with-tooltip :label="$t('features.building_class_tooltip')">
            <template #title>
                {{ $t('features.building_class') }}
            </template>
        </title-with-tooltip>
        <div
            class="is-flex choose-type-group"
            :class="[
                $vssWidth <= 1123 && 'is-flex-direction-column is-justify-content-center',
            ]"
        >
            <transition name="fade-opacity" :duration="{ leave: 0 }">
                <div
                    ref="box"
                    :class="{
                        'choose-type-box': true,
                        'not-choosen': building_class !== 'old',
                        chosen: building_class === 'old',
                        disable: !isDraft,
                    }"
                    @click="change_building_class('old')"
                >
                    <div class="type-icon home">
                        <svg-icon icon="home" hight="50" scale="1.4" color="#798DA6" />
                    </div>

                    <div class="type-text">
                        <div class="type-text-title has-text-weight-bold">
                            {{ $t('value.existing_building') }}
                        </div>
                        <div class="type-text-text">
                            {{
                                $t('value.existing_building_tooltip', {
                                    current_year: current_year - 1,
                                })
                            }}
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fade-opacity" :duration="{ leave: 0 }">
                <div
                    ref="box"
                    :class="{
                        'choose-type-box': true,
                        'not-choosen': building_class !== 'new',
                        chosen: building_class === 'new',
                        disable: !isDraft,
                    }"
                    @click="change_building_class('new')"
                >
                    <div class="type-icon with-stroke">
                        <svg-icon
                            icon="new-construction"
                            hight="50"
                            scale="1.4"
                            color="#798DA6"
                            :fill="false"
                        />
                    </div>

                    <div class="type-text">
                        <div class="type-text-title has-text-weight-bold">
                            {{ $t('value.new_building') }}
                        </div>
                        <div class="type-text-text">
                            {{
                                $t('value.new_building_tooltip', {
                                    current_year: current_year - 1,
                                })
                            }}
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fade-opacity" :duration="{ leave: 0 }">
                <div
                    v-if="allowedBuildingTypes.includes('construction_plot')"
                    class="choose-type-box"
                    :class="{
                        chosen: value === 'construction_plot',
                        'not-choosen': value !== 'construction_plot',
                        disable: !isDraft,
                    }"
                    @click="change_building_type('construction_plot')"
                >
                    <div class="type-icon home">
                        <svg-icon
                            icon="plot"
                            hight="50"
                            scale="1"
                            color="#798DA6"
                            :fill="false"
                        />
                    </div>
                    <div class="type-text">
                        <div class="type-text-title has-text-weight-bold">
                            {{ $t('value.construction_plot') }}
                        </div>
                        <div class="type-text-text">
                            {{ $t('value.construction_plot_tooltip') }}
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <title-with-tooltip
            v-if="building_class !== null"
            :label="$t('features.f_building_type_tooltip')"
        >
            <template #title>
                {{ $t('features.f_building_type') }}
            </template>
        </title-with-tooltip>
        <div
            v-if="building_class !== null"
            class="is-flex choose-type-group"
            :class="[
                $vssWidth <= 1123 && 'is-flex-direction-column is-justify-content-center',
            ]"
        >
            <transition
                v-for="building_type in [
                    `${building_type_prefix}house`,
                    `${building_type_prefix}apartment`,
                ]"
                :key="building_type"
                name="fade-opacity"
                :duration="{ leave: 0 }"
            >
                <div
                    v-if="allowedBuildingTypes.includes(building_type)"
                    ref="box"
                    :class="{
                        'choose-type-box': true,
                        'not-choosen': value !== building_type,
                        chosen: value === building_type,
                        disable: !isDraft,
                    }"
                    @click="$emit('update:buildingType', building_type)"
                >
                    <div class="type-icon house">
                        <svg-icon
                            v-if="['house', 'new_house'].includes(building_type)"
                            :icon="`${building_type_prefix}home`"
                            hight="50"
                            scale="1.4"
                            color="#798DA6"
                        />
                        <svg-icon
                            v-if="['apartment', 'new_apartment'].includes(building_type)"
                            :icon="`${building_type_prefix}apartment`"
                            hight="50"
                            scale="1.4"
                            color="#798DA6"
                        />
                    </div>

                    <div class="type-text">
                        <div class="type-text-title has-text-weight-bold">
                            {{ $t(`value.${building_type}`) }}
                        </div>
                        <div class="type-text-text">
                            {{ $t(`value.${building_type}_tooltip`) }}
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
const currentYear = new Date().getFullYear()
export default {
    name: 'BuildingType',
    props: {
        value: {
            type: String,
            default: null,
        },
        allowedBuildingTypes: {
            type: Array,
            default: () => {
                return [
                    'house',
                    'apartment',
                    'new_house',
                    'new_apartment',
                    'construction_plot',
                ]
            },
        },
    },
    data() {
        return {
            building_class: null,
            current_year: currentYear,
        }
    },
    computed: {
        ...mapGetters(['isDraft']),
        ...mapGetters('Address', ['getFeatures']),
        building_type_prefix() {
            return this.building_class === 'new' ? 'new_' : ''
        },
    },
    mounted() {
        this.adjust_building_class(this.getFeatures.f_building_type)
    },
    methods: {
        change_building_class(building_class) {
            this.building_class = building_class
            this.$emit('update:buildingType', null)
        },
        change_building_type(building_type) {
            this.$emit('update:buildingType', building_type)
            this.adjust_building_class(building_type)
        },
        adjust_building_class(building_type) {
            switch (building_type) {
                case 'house':
                case 'apartment':
                    this.building_class = 'old'
                    break
                case 'new_house':
                case 'new_apartment':
                    this.building_class = 'new'
                    break
                default:
                    this.building_class = null
            }
        },
    },
}
</script>

<style lang="scss">
@import '@/shared/assets/style/buefy.scss';
.choose-type-group {
    width: 100%;
    transition: 0.5s all;
    flex-wrap: wrap;
    gap: 1rem;
}
.choose-type-box {
    min-width: 360px;
    width: 34%;
    height: 140px;
    border-radius: 5px;
    box-shadow: 0 2px 14px 0 rgba(0, 47, 80, 0.1);
    border: solid 1px #d6e2e9;
    background-color: #ffffff;
    box-sizing: border-box;
    display: block;
    align-items: center;
    cursor: pointer;
    transition: 0.4s all;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // &:first-child {
    //     margin-right: 40px;
    // }
    &.disable {
        pointer-events: none !important;
    }

    & .type-icon {
        width: 80px;
        height: 80px;
        border: solid 1px rgba(78, 94, 114, 0.2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s all;
        float: left;
        margin-left: 40px;
        svg {
            transition: 0.3s all;
        }
        background-color: rgba(78, 94, 114, 0.1);
        &.with-stroke {
            stroke-dasharray: 3 1;
            svg {
                scale: 1.3;
            }
        }
    }

    .type-text {
        float: left;
        margin-left: 30px;
        .type-text-title {
            font-size: 18px;
            color: #001837;
            margin-bottom: 8px;
        }
        .type-text-text {
            width: 190px;
            font-size: 16px;
            line-height: 1.31;
            color: #798da6;
        }
    }

    &:hover,
    &.chosen {
        //border-color: $blue-primary;
        border: 1px solid #009cff;
        .type-icon {
            background-color: $primary;
            path {
                fill: #fff;
            }
            &.with-stroke {
                path {
                    fill: none;
                    stroke: #fff;
                }
            }
        }
        .type-text {
            .type-text-title {
                color: $primary;
            }
        }
    }
    &.not-choosen {
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }
}
@media screen and (max-width: 1123px) {
    .choose-type-box {
        width: 100%;
        max-width: 630px;
        .type-icon {
            margin-left: 30px;
            margin-right: 20px;
        }
    }
}
@media screen and (max-width: 1024px) {
    .choose-type-box {
        width: 100%;
        max-width: 630px;
        .type-icon {
            margin-left: 30px;
            margin-right: 20px;
        }
    }
}
</style>
