import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

// we can access router now, but I think better will be to pass router data to action call from component
//import router from '../router'

import Address from './address/index'
import auth from './Auth/index'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        customer_ref: null,
        valuation_request_ref: null,
        request_language: null,
        loading: false,
        status: null,
        helpPage: null,
        conflicting_valuation_requests: [],
        documents: null,
        unread_messages: null,
    }
}

const state = getDefaultState()

export default new Vuex.Store({
    state,
    getters: {
        getAddress(state, getters, rootState, rootGetters) {
            return rootGetters['Address/getAddress']
        },
        getFullStore(state, getters, rootState, rootGetters) {
            let data = { ...state }
            data.Address = rootGetters['Address/getFullBuildingData']
            return data
        },
        flow_type(state) {
            return process.env.VUE_APP_FLOW_TYPE
        },
        getFeatures(state, getters, rootState, rootGetters) {
            return rootGetters['Address/getFeatures']
        },
        get_map_scene2d(state, getters, rootState, rootGetters) {
            return rootGetters['Address/get_map_scene2d']
        },
        getDVMFeatures(state, getters, rootState, rootGetters) {
            return rootGetters['Address/getDVMFeatures']
        },
        getValuation(state, getters, rootState, rootGetters) {
            return rootGetters['Address/getValuation']
        },
        isDraft(state) {
            return state.status === 'draft'
        },
        getRequestRef(state) {
            return state.valuation_request_ref
        },
        getDocuments(state) {
            return state.documents
        },
        hasUnreadMessages(state) {
            return !!state.unread_messages
        },
    },
    mutations: {
        SET_CUSTOMER_REF(state, ref) {
            state.customer_ref = ref
        },
        SET_CONFLICTING_VALUATION_REQUESTS(state, conflicting_valuation_requests) {
            state.conflicting_valuation_requests = conflicting_valuation_requests
        },
        SET_VALUATION_REQUEST_REF(state, ref) {
            state.valuation_request_ref = ref
        },
        SET_REQUEST_LANGUAGE(state, lng) {
            state.request_language = lng
        },
        // update data from api store
        UPDATE_STATUS(state, data) {
            Object.assign(state, data)
        },
        RESET_VAL(state) {
            Object.assign(state, getDefaultState())
        },
        SET_HELP_PAGE(state, page) {
            state.helpPage = page
        },
        SET_DOCUMENTS(state, documents) {
            state.documents = documents
        },
        SET_UNREAD_MESSAGES(state, unread_messages) {
            state.unread_messages = unread_messages
        },
    },
    actions: {
        fetch_documents(context) {
            return axios
                .get(
                    utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                        '/request',
                        context.state.valuation_request_ref,
                        '/documents',
                    ])
                )
                .then((res) => {
                    let docs = res.data
                    docs.forEach((doc) => {
                        if (!doc.validation_purpose) doc.validation_purpose = doc.purpose
                    })
                    context.commit('SET_DOCUMENTS', docs)
                })
        },
        create_valuation_request(context, payload) {
            const { customer_ref, ignore_conflicts } = payload
            let params = { customer_ref }
            if (ignore_conflicts) {
                params.ignore_conflicts = 'true'
            }
            return axios
                .post(utils.val_urls.request, null, { params })
                .then((response) => {
                    context.commit(
                        'SET_VALUATION_REQUEST_REF',
                        response.data.valuation_request_ref
                    )
                    // context.commit('UPDATE_STATUS', { status: response.data.status })
                    context.commit('UPDATE_STATUS', { status: 'draft' })
                })
                .catch((error) => {
                    let response = error.response
                    if (
                        response.status === 403 &&
                        response.data.errors?.customer_ref_conflict
                    ) {
                        context.commit(
                            'SET_CONFLICTING_VALUATION_REQUESTS',
                            response.data.errors.customer_ref_conflict
                                .conflicting_valuation_requests
                        )
                    } else {
                        console.error(error)
                        throw error
                    }
                })
        },
        load_valuation_request(context) {
            return axios
                .get(
                    utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                        'request',
                        context.state.valuation_request_ref,
                    ]),
                    { params: { details: 'full' } }
                )
                .then((result) => {
                    context.commit('UPDATE_STATUS', {
                        status: result.data.status,
                    })

                    if (typeof result.data.customer_ref !== 'undefined') {
                        context.commit('SET_CUSTOMER_REF', result.data.customer_ref)
                    }

                    if (typeof result.data.last_submission_lang !== 'undefined') {
                        context.commit(
                            'SET_REQUEST_LANGUAGE',
                            result.data.last_submission_lang
                        )
                    }

                    // commit address info
                    if (typeof result.data.address !== 'undefined') {
                        context.commit('Address/SET_ADDRESS', result.data.address, {
                            root: true,
                        })
                    }
                    if (typeof result.data.features !== 'undefined') {
                        context.commit('Address/SET_FEATURES', result.data.features, {
                            root: true,
                        })
                    }

                    if (typeof result.data.created_at !== 'undefined') {
                        context.commit('Address/SET_CREATED_AT', result.data.created_at, {
                            root: true,
                        })
                    }

                    if (
                        typeof result.data.document_info !== 'undefined' &&
                        !utils.isEmptyObject(result.data.document_info)
                    ) {
                        context.dispatch('fetch_documents')
                    } else {
                        context.commit('SET_DOCUMENTS', null)
                    }

                    if (typeof result.data.unread_messages) {
                        context.commit('SET_UNREAD_MESSAGES', result.data.unread_messages)
                    }

                    if (typeof result.data.dvm_rejections !== 'undefined') {
                        context.commit(
                            'Address/SET_REJECTIONS',
                            result.data.dvm_rejections,
                            {
                                root: true,
                            }
                        )
                    }

                    context.dispatch('Address/fetchStreetviewInfo', { root: true })

                    if (typeof result.data.avm_transaction !== 'undefined') {
                        context.commit(
                            'Address/SET_AVM',
                            {
                                avm_transaction: result.data.avm_transaction,
                                avm_rental: result.data.avm_rental,
                            },
                            {
                                root: true,
                            }
                        )
                    }
                    if (typeof result.data.last_dvm_rejection !== 'undefined') {
                        context.commit(
                            'Address/SET_LAST_DVM_REJECTION',
                            result.data.last_dvm_rejection,
                            {
                                root: true,
                            }
                        )
                    }
                    if (result.data.valuation) {
                        let date = result.data.valuation.date
                        if (Array.isArray(date)) {
                            date = date[0]
                        }
                        if (typeof date === 'string') {
                            date = new Date(date)
                        }

                        // If it's set, take it from valuation
                        // If it's not set but the request is not valued: default is false
                        // Else (aka if a valuation has been done in the past): default is true
                        let override_price =
                            typeof result.data.valuation.override_price !== 'undefined'
                                ? result.data.valuation.override_price
                                : utils.isEmptyObject(result.data.valuation)
                                ? false
                                : true

                        if (override_price) {
                            let {
                                market_value,
                                forced_sale_value,
                                rental_value,
                                reconstruction_value,
                            } = result.data.valuation

                            let prices_override = {
                                market_value,
                                forced_sale_value,
                                rental_value,
                                reconstruction_value,
                            }

                            context.commit('Address/SET_PRICES_OVERRIDE', prices_override)
                        }

                        context.commit(
                            'Address/SET_VALUATION',
                            {
                                ...result.data.valuation,
                                date: date,
                                reconstruction_value:
                                    result.data.valuation.reconstruction_value &&
                                    process.env.VUE_APP_RECONSTRUCTION_VALUE_ENABLED.toLowerCase() ===
                                        'true'
                                        ? result.data.valuation.reconstruction_value
                                        : null,
                                override_price,
                                internal_remarks:
                                    result.data.valuation.internal_remarks || '',
                            },
                            {
                                root: true,
                            }
                        )
                    }
                    if (typeof result.data.dvm_features !== 'undefined') {
                        context.commit(
                            'Address/SET_DVM_FEATURES',
                            result.data.dvm_features,
                            {
                                root: true,
                            }
                        )
                    }
                    if (typeof result.data.map_scene2d !== 'undefined') {
                        context.commit(
                            'Address/SET_MAP_SCENE2D',
                            result.data.map_scene2d,
                            {
                                root: true,
                            }
                        )
                    } else if (result.data.features.building_id) {
                        context.dispatch('Address/fetch_map_data')
                    }
                    if (typeof result.data.map_scene3d !== 'undefined') {
                        context.commit(
                            'Address/SET_MAP_SCENE3D',
                            result.data.map_scene3d,
                            {
                                root: true,
                            }
                        )
                    }
                    if (typeof result.data.other_data !== 'undefined') {
                        context.commit('Address/SET_OTHER_DATA', result.data.other_data, {
                            root: true,
                        })
                    }

                    if (
                        !result.data.hasOwnProperty('other_data') ||
                        !result.data.other_data.hasOwnProperty('extra_info_edited')
                    ) {
                        const hasExtraInfo =
                            result.data.features.hasOwnProperty('f_construction_year') &&
                            !!result.data.features.f_construction_year
                        context.commit(
                            'Address/SET_OTHER_DATA',
                            { extra_info_edited: hasExtraInfo },
                            {
                                root: true,
                            }
                        )
                    }
                })
                .catch((error) => {
                    console.error({ error })
                })
        },
    },
    modules: {
        Address,
        auth,
    },
})
