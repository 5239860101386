/* eslint-disable */
require('./apartment')
require('./check')
require('./energy')
require('./home')
require('./house')
require('./plot')
require('./insulation')
require('./location')
require('./new-house')
require('./new-apartment')
require('./new-construction')
require('./person')
require('./heating-fuel-gas')
require('./heating-fuel-oil')
require('./heating-fuel-wood')
require('./heating-fuel-pellets')
require('./heating-fuel-coal')
require('./heating-fuel-district-heating')
require('./heating-fuel-electric')
require('./processing')
require('./ruler')
require('./yes')
require('./no')
require('./idk')
require('./duplicates')
