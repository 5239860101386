<i18n>
{
  "nl-BE": {
    "admin": "Admin",
    "logout": "Afmelden",
    "login": "Log in",
    "dashboard": "Dashboard",
    "support": "Support",
    "new_request": "New Request"
  },
  "en-BE": {
    "admin": "Admin",
    "logout": "Logout",
    "login": "Log in",
    "dashboard": "Dashboard",
    "support": "Support",
    "new_request": "New Request"
  },
  "fr-BE": {
    "admin": "Admin",
    "logout": "Déconnexion",
    "login": "S'identifier",
    "dashboard": "Dashboard",
    "support": "Support",
    "new_request": "New Request"
  }
}
</i18n>

<template>
    <b-navbar id="navbar" type="is-white" :class="{ 'is-fixed-top': fixed }">
        <!-- this can be link to main page -->
        <template slot="brand">
            <b-navbar-item
                tag="router-link"
                :to="
                    email
                        ? {
                              name: 'request',
                              params: { action: 'start', lang: this.$i18n.locale },
                          }
                        : { name: 'login', params: { lang: this.$i18n.locale } }
                "
            >
                <img
                    src="../assets/icons/rockestate-logo-horizontal-dark.svg"
                    class="logo"
                    width="148"
                />
            </b-navbar-item>
        </template>

        <template v-if="showMenu" slot="start">
            <b-navbar-item
                tag="router-link"
                :to="{
                    name: 'dashboard',
                    params: {
                        lang: $route.params.lang,
                    },
                }"
            >
                {{ $t('dashboard') }}
            </b-navbar-item>
            <b-navbar-item
                tag="router-link"
                :to="{
                    name: 'support',
                    params: {
                        lang: $route.params.lang,
                    },
                    query: {
                        prev_name: $route.name,
                        prev_path: $route.path,
                        ...$route.query,
                    },
                }"
            >
                {{ $t('support') }}
            </b-navbar-item>
            <b-navbar-item
                tag="router-link"
                :to="{
                    name: 'request',
                    params: {
                        action: 'start',
                        lang: $route.params.lang,
                    },
                }"
            >
                {{ $t('new_request') }}
            </b-navbar-item>
            <b-navbar-item
                v-if="roles.includes('admin')"
                tag="router-link"
                :to="{
                    name: 'admin',
                    params: {
                        lang: $route.params.lang,
                    },
                }"
            >
                {{ $t('admin') }}
            </b-navbar-item>
            <div v-if="isValuer && isDispatchDashboard" class="deployment-select mr-2">
                <b-select expanded :disabled="isLoading">
                    <option disabled>Deployment</option>
                </b-select>
            </div>
            <div
                v-if="isValuer"
                ref="reference_field"
                class="ref-search"
                :class="{
                    'apply-shake': shake,
                }"
            >
                <div class="ref-form">
                    <b-input
                        v-model="dvm_ref"
                        placeholder="Go to DVM (enter ref)"
                        :disabled="isLoading"
                    />
                    <b-button
                        class="ml-2"
                        type="is-primary"
                        :disabled="isDvmRefEmpty || isLoading"
                        @click="goToDvm(false)"
                    >
                        Go
                    </b-button>
                    <b-button
                        class="ml-2"
                        type="is-primary"
                        :disabled="isDvmRefEmpty || isLoading"
                        @click="goToDvm(true)"
                    >
                        Go (new tab)
                    </b-button>
                </div>
            </div>
        </template>

        <template slot="end">
            <div
                v-if="showRoleSwitches"
                class="role-switches mr-4 is-flex is-align-items-center is-align-self-center"
            >
                <b-switch
                    :value="roles.includes('admin')"
                    size="is-small"
                    @input="toggleRole('admin')"
                >
                    Admin
                </b-switch>
                <b-switch :value="isValuer" size="is-small" @input="toggleRole('valuer')">
                    Valuer
                </b-switch>
                <b-switch
                    :value="isSubstitute"
                    size="is-small"
                    @input="toggleRole('substitute')"
                >
                    Substitute
                </b-switch>
            </div>
        </template>

        <template slot="end">
            <b-navbar-item tag="div">
                <div class="langs buttons has-addons my-0">
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': this.$route.params.lang === 'nl-BE',
                        }"
                        @click.native="changeLang('nl-BE')"
                    >
                        <span>NL</span>
                    </b-button>
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': this.$route.params.lang === 'fr-BE',
                        }"
                        @click.native="changeLang('fr-BE')"
                    >
                        <span>FR</span>
                    </b-button>
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': this.$route.params.lang === 'en-BE',
                        }"
                        @click.native="changeLang('en-BE')"
                    >
                        <span>EN</span>
                    </b-button>
                </div>
                <div class="mr-4 px-1 is-size-7 user" :class="[email ? 'ml-6' : 'ml-4']">
                    {{ email }}
                </div>
                <div class="buttons">
                    <a v-if="email != null" :href="logout_url">
                        <b-button size="is-small" type="is-primary" outlined>
                            <span>{{ $t('logout') }}</span>
                        </b-button>
                    </a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import utils from '@/shared/plugins/utils'
export default {
    name: 'Navbar',
    props: {
        email: {
            type: String,
            default: null,
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        shake: false,
        dvm_ref: null,
        isLoading: false,
    }),
    computed: {
        logout_url: function() {
            return utils.urlJoin(process.env.VUE_APP_AUTH_API_URL, 'logout')
        },
        showMenu() {
            return (
                process.env.VUE_APP_CHECK_LOGIN.toLowerCase() === 'false' ||
                this.$store.state.auth.authenticated
            )
        },
        showRoleSwitches() {
            return process.env.NODE_ENV.toLowerCase() === 'development'
        },
        roles() {
            return this.$store.state.auth.roles
        },
        isValuer() {
            return this.$store.state.auth.roles.includes('valuer')
        },
        isSubstitute() {
            return this.$store.state.auth.roles.includes('substitute')
        },
        isDispatchDashboard() {
            return (
                typeof process.env.VUE_APP_DISPATCHED_DEPLOYMENTS !== 'undefined' &&
                process.env.VUE_APP_DISPATCHED_DEPLOYMENTS !== ''
            )
        },
        isDvmRefEmpty() {
            return !this.dvm_ref || utils.isEmptyStr(this.dvm_ref)
        },
    },
    watch: {
        fixed() {
            document.body.classList.toggle('has-navbar-fixed-top', this.fixed)
        },
    },
    mounted() {
        try {
            this.$nextTick(() => {
                this.$refs.reference_field.addEventListener('animationend', () => {
                    this.shake = false
                })
            })
        } catch (e) {
            console.log({ e })
        }
    },
    methods: {
        ...mapMutations(['RESET_VAL']),
        ...mapMutations('auth', ['TOGGLE_ROLE']),
        changeLang(lang) {
            this.$i18n.locale = lang
            localStorage.setItem('lang', lang)
            let params = this.$route.params
            params.lang = lang
            this.$router.push({
                name: this.$route.name,
                query: this.$route.query,
                params: params,
            })
        },
        toggleValuer() {
            this.TOGGLE_ROLE('valuer')
        },
        toggleSubstitute() {
            this.TOGGLE_ROLE('substitute')
        },
        toggleRole(role) {
            this.TOGGLE_ROLE(role)
        },
        shakeAnimation() {
            this.shake = true
        },
        goToDvm(newTab = false) {
            if (
                this.$route.name === 'dvm' &&
                this.dvm_ref === this.$route.query.valuation_request_ref &&
                !newTab
            ) {
                this.shakeAnimation()
                return
            }

            this.isLoading = true
            this.$axios
                .get(
                    utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                        'request',
                        this.dvm_ref,
                    ])
                )
                .then(() => {
                    if (newTab) {
                        const routeData = this.$router.resolve({
                            name: 'dvm',
                            query: {
                                valuation_request_ref: this.dvm_ref,
                            },
                        })
                        window.open(routeData.href, '_blank')
                        this.isLoading = false
                        return
                    }
                    this.RESET_VAL()
                    this.$router.push({
                        name: 'dvm',
                        query: {
                            valuation_request_ref: this.dvm_ref,
                        },
                    })
                    this.isLoading = false
                })
                .catch((e) => {
                    this.isLoading = false
                    console.log({ e })
                    this.shakeAnimation()
                })
        },
    },
}
</script>

<style lang="scss" scoped>
// no need for separate file less load with scope
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-2px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(4px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-8px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(8px, 0, 0);
    }
}

.apply-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

#navbar {
    padding: 0 15px;
    border-bottom: 1px solid #d6e2e9;
}

.role-switches {
    height: 100%;
}

.ref-search {
    z-index: 99998;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    .ref-form {
        display: flex;
        flex-direction: row;
    }
}

.deployment-select {
    display: flex;
    flex-direction: row;
    align-items: center;
}
</style>
